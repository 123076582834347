import { graphql } from "gatsby";
import React from "react";
import SEO from "../components/Seo";
import Layout from "../containers/Layout";
import Section from "../containers/Section";
import Hero from "../components/AmazonAcceleratorProgramPage/Hero";
import PerpetuaTool from "../components/AmazonAcceleratorProgramPage/PerpetuaTool";
import PricingCard from "../components/PricingCard";
import Perpetua4Features from "../components/AmazonAcceleratorProgramPage/Perpetua4Features";

interface AmazonAcceleratorProgramProps {
  data: any;
}

const pricingCards = [
  {
    title: "Starter",
    displayedTitle: "Plan A",
    price: "$50",
    pricePerMonth: "月額",
    taxes: "月額広告費10万円未満"
  },
  {
    title: "Starter",
    displayedTitle: "Plan B",
    price: "$125",
    pricePerMonth: "月額",
    taxes: "月額広告費10~25万円未満"
  },
  {
    title: "Starter",
    displayedTitle: "Plan C",
    price: "$250",
    pricePerMonth: "月額",
    taxes: "月額広告費25~50万円未満"
  },
  {
    title: "Starter",
    displayedTitle: "Plan D",
    price: "$500",
    pricePerMonth: "月額",
    taxes: "月額広告費50~100万円未満"
  },
  {
    title: "Starter",
    displayedTitle: "Plan E",
    price: "月額広告費の6.5%相当のUSD",
    taxes: "月額広告費100万円以上"
  }
];

const perpetuaFeatures = [
  {
    title: "圧倒的なコストメリット",
    description: ["月額$50から!", "初期導入費用と解約金など", "一切不要。"]
  },
  {
    title: "使い方も簡単♪",
    description: ["複雑な設定は不要。", "初心者でも簡単に扱える", "管理画面。"]
  },
  {
    title: "運用はわずかな時間で",
    description: [
      "自動最適化で",
      "大幅な工数削減を実現。",
      "24時間365日自動最適化がかかります。"
    ]
  },
  {
    title: "手厚いサポート体制",
    description: [
      "電話・メールの",
      "サポートを無償で提供。",
      "いつでもご相談いただけます。"
    ]
  }
];

const AmazonAcceleratorProgram: React.FC<AmazonAcceleratorProgramProps> = ({
  data
}) => {
  const pageData = data;

  const heroCta = {
    text: "今すぐ申し込む",
    route: "/"
  };

  return (
    <Layout>
      <SEO
        title={
          "プライベートオファー2ヶ月フリートライアル!| Perpetua(パーペチュア)"
        }
        description={
          "Amazon広告最適化ツール「Perpetua」日本での導入社数700社以上！非常に使いやすいダッシュボードと、きめ細かなサポートでお客様のAmazonビジネスの拡大・成功はもとより、運用工数の削減も実現いたします。"
        }
        image={pageData?.openGraphImage?.nodes[0]?.url}
        robots={"noindex, nofollow"}
      />

      <Hero
        title={["プライベートオファー", "2ヶ月フリートライアル!"]}
        cta={heroCta}
      />

      <PerpetuaTool
        title={["Amazonビジネスの 成長 に", "欠かせないツールPerpetua"]}
        subTitle={"Perpetuaとは"}
        description={[
          "Amazon広告最適化ツール「Perpetua」日本での導入社数700社以上!",
          "非常に使いやすいダッシュボードと、きめ細かなサポートで",
          "お客様のAmazonビジネスの拡大・成功はもとより、",
          "運用工数の削減も実現いたします。"
        ]}
      />

      <Section className="pt-22 lg:pt-32 md:pb-24">
        <div className="w-full mx-auto mb-10 lg:mb-18">
          <p className="text-indigo-1 font-semibold text-center text-3xl lg:text-2xl">
            ツール費用
          </p>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-10 mx-auto justify-center mb-10">
          {pricingCards?.map((item, index) => {
            return (
              <PricingCard
                key={index}
                variant={item?.title?.toLowerCase()}
                data={item}
              />
            );
          })}
        </div>
      </Section>

      <Perpetua4Features
        title={"Perpetuaの4つのポイント"}
        features={perpetuaFeatures}
      />
    </Layout>
  );
};

export default AmazonAcceleratorProgram;

export const data = graphql`
  query {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
    openGraphImage: allContentfulAsset(
      filter: { contentful_id: { eq: "fiLjgWGZqsdq7wQIl3MYa" } }
    ) {
      nodes {
        url
      }
    }
  }
`;
