import React from "react";
import Paragraph from "../../Paragraph";
import Header, { HeaderVariant } from "../../Header";
import Section from "../../../containers/Section";
import { GatsbyImage } from "gatsby-plugin-image";
import { useContentfulImage } from "gatsby-source-contentful/hooks";

interface PerpetuaToolProps {
  title: string[];
  subTitle: string;
  description: string[];
}

const PerpetuaTool: React.FC<PerpetuaToolProps> = props => {
  const { title, subTitle, description } = props;

  const image = useContentfulImage({
    image: {
      url: "//images.ctfassets.net/2c85gj41xf35/57cpNEGXMyXoLaIsPitF6e/bf45f1b8bd49b8e483d2126ed28e6ecd/amazon-hero-ja.png",
      width: 1996,
      height: 1549,
      quality: 100
    }
  });

  return (
    <div className="mt-22 lg:mt-37">
      <Section className="flex flex-col lg:flex-row items-center">
        <div className="lg:text-left w-full lg:w-1/2 text-center md:text-left">
          <Header className="text-indigo-1 !mb-3" variant={HeaderVariant.h2}>
            <span className="text-2xl font-semibold">{subTitle}</span>
          </Header>

          <Header variant={HeaderVariant.h3}>
            {title?.map(item => {
              return <span className="block">{item}</span>;
            })}
          </Header>
          <Paragraph className="text-2xl mt-12 text-gray-3-dark">
            {description?.map(item => {
              return <span className="block">{item}</span>;
            })}
          </Paragraph>
        </div>
        <div className="w-full lg:w-1/2 mt-8 lg:mt-0">
          <GatsbyImage alt="" image={image} />
        </div>
      </Section>
    </div>
  );
};

export default PerpetuaTool;
