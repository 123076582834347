import React from "react";
import Paragraph from "../../Paragraph";
import Header, { HeaderVariant } from "../../Header";
import DemoFormHubspot from "../../DemoPage/DemoFormHubspot/DemoFormHubspot";
import { PAGE } from "../../../constants/pages";

interface Perpetua4FeaturesProps {
  title: string;
  features: {
    title: string;
    description: string[];
  }[];
}

const Perpetua4Features: React.FC<Perpetua4FeaturesProps> = props => {
  const { title, features } = props;

  return (
    <div className="px-5 pt-20 pb-96 lg:p-20 mt-22 lg:mt-37 mb-[80rem] sm:mb-[50rem] lg:mb-150 bg-green-3-light rounded-tl-large rounded-br-large">
      <div className="flex flex-col lg:flex-row items-center max-w-8xl mx-auto px-0 sm:px-12 lg:px-4 relative">
        <div className="lg:w-1/2 w-full text-left">
          <Header variant={HeaderVariant.h3}>{title}</Header>
          <div className="grid grid-cols-1 sm:grid-cols-2 sm:grid-rows-2 gap-8 mt-6">
            {features.map((feature, index) => {
              return (
                <div key={index}>
                  <span className="block text-indigo-1 font-bold text-3xl">
                    {index + 1}
                  </span>
                  <p className="font-bold my-3 text-2xl">{feature?.title}</p>
                  <p className="text-sm">
                    {feature?.description?.map(item => {
                      return <span className="block">{item}</span>;
                    })}
                  </p>
                </div>
              );
            })}
          </div>
          <Paragraph className="text-2xl mt-12 text-gray-3-dark"></Paragraph>
        </div>
        <div id="demoForm" className="relative lg:absolute w-full lg:w-1/2 right-0 top-0">
          <DemoFormHubspot page={PAGE.AMAZON_ACCELERATOR_PROGRAM} />
        </div>
      </div>
    </div>
  );
};

export default Perpetua4Features;
