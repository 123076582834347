import classNames from "classnames";
import React from "react";

interface ParagraphProps extends React.HTMLAttributes<HTMLParagraphElement> {}

const Paragraph: React.FC<React.PropsWithChildren<ParagraphProps>> = props => {
  const { className, children, ...rest } = props;
  return (
    <p className={classNames("text-xl font-normal", className)}>{children}</p>
  );
};

export default Paragraph;
