import React from "react";
import Header, { HeaderVariant } from "../../Header";
import Section from "../../../containers/Section";
import Badge from "../../Badge";
import { IconType } from "../../Icon";
import Button, { ButtonSize, ButtonVariant } from "../../Button";
import { trackView } from "../../../utils/analytics";
import { Category, Name, Type } from "../../../utils/analytics/constants";
import LogoWordmark from "../../../components/NavigationBar/assets/logo-wordmark.svg";
import { StaticImage } from "gatsby-plugin-image";
import cn from "classnames";

interface HeroProps {
  title: string[];
  cta: {
    text: string;
    route: string;
  };
}

const Hero: React.FC<HeroProps> = props => {
  const { title, cta } = props;

  return (
    <Section className="mt-35">
      <div className="w-full mx-auto text-center flex flex-col sm:items-start lg:items-center">
        <div className="flex items-center justify-center">
          <LogoWordmark />
          <StaticImage
            className="md:ml-12 ml-6"
            width={100}
            alt="amazon-partner"
            src="../../../images/amazon/amazon-partner.png"
          />
        </div>
        <Badge
          iconType={IconType.rocket}
          title={"Amazon Private Brand Accelerator "}
          h1BadgeShadow={true}
          className="mb-12 mt-16 mx-auto md:mx-0"
        />
        <Header
          variant={HeaderVariant.h2}
          className="md:text-5xl lg:text-7xl lg:px-4 text-[28px]"
        >
          {title.map((line, index) => {
            return (
              <span
                key={index}
                className={cn(
                  "block font-black",
                  index === 0 && "lg:max-w-4xl"
                )}
              >
                {line}
              </span>
            );
          })}
        </Header>
        <Button
          variant={ButtonVariant.primary}
          size={ButtonSize.large}
          text={cta?.text}
          onClick={() => {
            trackView(Name.Cta, {
              category: Category.ButtonClick,
              type: Type.Button,
              text: cta?.text,
              page_url: window?.location.href,
              destination: cta?.route,
              location: "Amazon Accelerator Program Hero"
            });
            const el = document.getElementById('demoForm');
            if (el) {
              el.scrollIntoView({ behavior: "smooth", block: "start" });
            }
          }}
        />
      </div>
    </Section>
  );
};

export default Hero;
